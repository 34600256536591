/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "styled-components";

import Header from "./header";
import Footer from "./footer";
import { theme } from "../libs/theme";
import "./layout.css";

const Layout = ({ children, lang, misc }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Patua+One"
      ></link>
      <link rel="shortcut icon" href="/LM-RGB22.png" type="image/x-icon"></link>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        lang={lang}
        misc={misc}
      />
      <div>
        <main>{children}</main>
      </div>
      <Footer></Footer>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
