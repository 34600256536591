export const theme = {
  colors: {
    dark: "rgb(68, 68, 68)",
    grey: "rgb(168, 168, 168)",
    lightGrey: "rgb(115, 126, 134)",
    paleGrey: "#f9f9f9",
    white: "#FFFFFF",
    black: "#000000",
    orange: "#ed703a",
  },
};
