import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { fadeIn } from "react-animations";
import PropTypes from "prop-types";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, useStaticQuery, graphql, navigate } from "gatsby";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import {
  Container,
  FlexContainer,
  displayIn,
  flexCentered,
} from "../components/common";
import { StaticImage } from "gatsby-plugin-image";

const Animation = keyframes`${fadeIn}`;

const animatedOpen = css`
  display: block;
  animation: 0.3s ${Animation};
`;

const StyledHeader = styled.header`
  background: ${props => props.theme.colors.paleGrey};
`;

const Navbar = styled.div`
  display: flex;
  align-items: center;
  ${displayIn}

  margin-left: 16px;

  a {
    text-decoration: none;

    &:hover,
    .active {
      position: relative;
      color: ${props => props.theme.colors.orange};
      &::after {
        position: absolute;
        content: "";
        height: 4px;
        left: 10%;
        top: 50%;
        margin-top: 20px;
        width: 80%;
        background-color: ${props => props.theme.colors.orange};
      }
    }

    span {
      display: block;
      line-height: 50px;
      padding: 0 20px;
      white-space: nowrap;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      color: ${props => props.theme.colors.dark};
      &:hover {
        color: ${props => props.theme.colors.orange};
      }
    }

    &:last-child {
      span {
        border-right: none;
      }
    }
  }
`;

const BurgerIcon = styled.div`
  color: ${props => props.theme.colors.orange};
  font-size: 1.5rem;
  cursor: pointer;
`;

const MobileMenuIconContainer = styled.div`
  ${displayIn}
`;

const Menu = styled.div`
  position: absolute;
  top: 80px;
  right: 80px;
  z-index: 1;
  display: none;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
  }

  @media (min-width: 992px) {
    display: none;
  }

  a {
    padding: 16px;
    display: block;
    text-decoration: none;
    background: ${props => props.theme.colors.paleGrey};
    color: ${props => props.theme.colors.dark};

    &:hover {
      background: #f0f0f0;
      color: ${props => props.theme.colors.orange};
    }
  }

  ${props => props.open && animatedOpen}
`;
const StyledDropdown = styled(Dropdown)`
  @media (max-width: 762px) {
    .Dropdown-control {
      border: none;
    }

    .Dropdown-control:hover {
      box-shadow: none;
    }
  }

  align-self: center;
  justify-self: flex-end;

  .Dropdown-control {
    background: none;
    border-radius: 3px;
    padding: 8px 32px 8px 10px;
  }

  .Dropdown-menu {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;

const LogoContainer = styled.div`
  ${displayIn}
  a {
    ${flexCentered}
  }
`;

const langMapping = {
  "zh-hk": "繁",
  "zh-cn": "简",
  "en-gb": "En",
};

const isActive = ({ isCurrent, href }) => {
  if (isCurrent) {
    return {
      className: "active",
    };
  }
};

const StyledLink = props => (
  <Link getProps={props.isDesktop && isActive} {...props} />
);

const Header = ({ siteTitle, lang, misc }) => {
  const options = ["En", "繁", "简"];
  const defaultOption = langMapping[lang] || "En";
  const [shouldMobileMenuOpen, setShouldMobileMenuOpen] = useState(false);

  const MobileMenuIcon = props => (
    <BurgerIcon
      as={AiOutlineMenu}
      onClick={() => setShouldMobileMenuOpen(!shouldMobileMenuOpen)}
      {...props}
    />
  );

  const getLinkByLang = path => {
    return `${lang !== "en-gb" ? `/${lang}` : ""}${path}`;
  };

  const Logo = props => (
    <LogoContainer {...props}>
      <Link to={getLinkByLang("/")}>
        <StaticImage
          src="../images/LM-RGB22.png"
          alt="logo"
          height={60}
        ></StaticImage>
      </Link>
    </LogoContainer>
  );

  const Links = ({ isDesktop }) => {
    return (
      <>
        <StyledLink to={getLinkByLang("/")} isDesktop={isDesktop}>
          <span>{misc.home}</span>
        </StyledLink>
        <StyledLink to={getLinkByLang("/rooms")} isDesktop={isDesktop}>
          <span>{misc.room}</span>
        </StyledLink>
        <StyledLink to={getLinkByLang("/contact")} isDesktop={isDesktop}>
          <span>{misc.contact}</span>
        </StyledLink>
      </>
    );
  };

  return (
    <StyledHeader>
      <Container
        style={{
          margin: `0 auto`,
          height: "80px",
          justifyContent: "space-between",
        }}
        flex
        align-items="center"
      >
        <Left>
          <MobileMenuIconContainer mobile style={{ width: "80px" }}>
            <MobileMenuIcon></MobileMenuIcon>
          </MobileMenuIconContainer>

          <Logo tablet desktop />
          <Navbar desktop="flex">
            <Links isDesktop={true}></Links>
          </Navbar>
        </Left>
        <Logo mobile="flex"></Logo>
        <Right>
          <MobileMenuIconContainer tablet style={{ marginRight: "16px" }}>
            <MobileMenuIcon></MobileMenuIcon>
          </MobileMenuIconContainer>
          <Menu open={shouldMobileMenuOpen}>
            <Links></Links>
          </Menu>
          <StyledDropdown
            options={options}
            onChange={({ value: optValue }) => {
              const key = Object.entries(langMapping).find(
                ([k, v]) => v === optValue
              )[0];
              const path = window.location.pathname;
              const newPath = `${
                key !== "en-gb" ? `/${key}` : ""
              }${path.replace(`/${lang}/`, "/")}`;
              navigate(newPath + window.location.search);
            }}
            value={defaultOption}
            placeholder="Select an option"
          />
        </Right>
      </Container>
    </StyledHeader>
  );
};

export default Header;
