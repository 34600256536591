import * as React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GoMail } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FlexContainer, Column, Container } from "./common/index.js";
import styled from "styled-components";

const StyledFooter = styled.footer`
  width: 100%;
  background: #0a0a0a;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff !important;
`;

const ContactLink = styled.a`
  margin: 0 8px;
  color: white;
  text-decoration: none;
  color: #fff !important;
`;

const ContactColumn = styled(Column)`
  text-align: center;

  @media (min-width: 992px) {
    text-align: left;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <FlexContainer>
          <ContactColumn size={3}>
            <BsFillTelephoneFill></BsFillTelephoneFill>
            <big>
              <ContactLink href="tel:37528808">37528808</ContactLink>
            </big>
            <AiOutlineMail />
            <big>
              <ContactLink href="mailto:contactus@lemanndi.com">
                contactus@lemanndi.com
              </ContactLink>
            </big>
          </ContactColumn>
          <Column size={3} centered>
            <span
              class="big"
              style={{
                color: "white",
              }}
            >
              © 2022 Le Manndi. All Rights Reserved.
            </span>
          </Column>
          <Column size={3} centered>
            <big>
              <ContactLink
                target="_blank"
                href="https://www.facebook.com/LeManndi/"
              >
                <FaFacebookF />
              </ContactLink>
            </big>
          </Column>
        </FlexContainer>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
