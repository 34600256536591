import styled, { css } from "styled-components";

export const displayIn = css`
  display: ${props =>
    props.mobile ? (props.mobile === "flex" ? "flex" : "block") : "none"};

  @media (min-width: 768px) {
    display: ${props =>
      props.tablet ? (props.tablet === "flex" ? "flex" : "block") : "none"};
  }

  @media (min-width: 992px) {
    display: ${props =>
      props.desktop ? (props.desktop === "flex" ? "flex" : "block") : "none"};
  }
`;

export const flexCentered = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const flex = css`
  display: flex;
  flex-direction: ${props => props.flex || "row"};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
`;

const centered = css`
  text-align: center;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  ${props => props.flex && flex}
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Row = styled.div``;

export const Column = styled.div`
  @media (min-width: 992px) {
    flex: 0 0
      calc(${props => (1 / props.size) * 100}% - ${props => props.gap || "0px"});
  }
  flex: 0 0 100%;
  ${props => props.centered && centered}
`;

export const SectionTitle = styled.h2`
  font-size: 42px;
  line-height: 42px;
`;

const orange = css`
  background: ${props => props.theme.colors.orange};
  color: ${props => props.theme.colors.white};
  border: none;

  &:visited {
    color: ${props => props.theme.colors.white};
  }
`;

const dark = css`
  background: ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.white};

  &:visited {
    color: ${props => props.theme.colors.white};
  }
`;

const outlineOrange = css`
  background: rgba(0, 0, 0, 0);
  color: ${props => props.theme.colors.orange};
  border: 1px solid ${props => props.theme.colors.orange};

  &:visited {
    color: ${props => props.theme.colors.orange};
  }
`;

export const Button = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 11px 20px;
  border-radius: 3px;
  text-decoration: none;
  text-align: center;
  ${props => {
    if (props.color === "orange") {
      return orange;
    }
    if (props.color === "dark") {
      return dark;
    }
    if (props.color === "outline-orange") {
      return outlineOrange;
    }
  }}
`;

const inputCss = css`
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 16px 18px;
  background-position: 98% 50%;
  color: #626262;
  background-color: rgba(255, 255, 255, 1);
  border-color: #ebebeb;
  box-shadow: inset 0 0 2px 2px rgb(0 0 0 / 2%);
  padding: 10px;
  outline: none;
  margin: 0;
  width: 100%;
  min-width: 230px;
  display: block;
  margin-bottom: 20px;
  font-size: 13px;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-appearance: none;

  &:focus {
    color: #1982c2;
    background-color: rgba(233, 245, 252, 1) !important;
    border-color: #d5e5ee;
  }

  @media (min-width: 762px) {
    width: auto;
  }
`;

export const Input = styled.input`
  ${inputCss};
`;

export const Textarea = styled.textarea`
  ${inputCss};
`;
